import React, { useState, useRef, useEffect } from 'react';
import CardRounded from '../CardRounded';
import AddButton from '../helpers/AddButton';
import { BsFilterLeft } from 'react-icons/bs';
import { Select, Skeleton, Tooltip, Popconfirm, message } from 'antd';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/es';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getCommentsCrm, removeAlert } from '../../api/crm';
import Seguimiento from '../../assets/icons/Seguimiento';
import { useAuth } from '../../context/AuthProvider';
import {  compareByCreatedAtOrSentAt } from '../../services/utils/compareByDate';
import { t } from 'i18next';
import { useLoaderData } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalState';

import utc from 'dayjs/plugin/utc';
import CrmMessage from '../helpers/CrmMessage';
dayjs.extend(utc);
dayjs.extend(LocalizedFormat);
dayjs.locale('es');
export default function CRM({
  handleOpenCRM,
  CRM_OPTIONS,
  member_id,
  crm_list,
  isLoading,
  sales,
  handleOpenReturn,
  coachHistories
}) {
  
  if (!member_id) return null;
  const queryClient = useQueryClient();
  const data = useLoaderData();
  const [filterSelected, setfilterSelected] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [messageApi, messageContext] = message.useMessage();
  const [appComments, setappComments] = useState([]);
  const { user } = useAuth();
  const { state, dispatch } = useGlobalContext();
  const filterRef = useRef(null);
  const onChangeFilter = (value) => {
    filterRef.current.blur();
    setfilterSelected(value);
  };
  const confirm = async (id) => {
    // console.log(e);
    processing();
    setConfirmLoading(true);
    try {
      const result = await useDelete_CRM.mutate(id);
    } catch (error) {
      console.log('error', error);
    }
    setConfirmLoading(false);
    setOpen(false);
    // setConfirmLoading(true);
  };
  const cancel = (e) => {
    setOpen(false);
  };
  const useDelete_CRM = useMutation({
    mutationFn: (alert) => removeAlert(alert),
    onSuccess: () => {
      // openNotification('success');
      messageApi.destroy();

      queryClient.invalidateQueries({
        queryKey: ['crm', member_id],
      });
    },
    onError: (error) => {
      messageApi.destroy();

      // openNotification('error');
    },
  });
  const processing = () => {
    messageApi.open({
      type: 'loading',
      content: 'Procesando',
      duration: 0,
      // style: { backgroundColor: '#222222', color: 'white' },
    });
  };

  const fetchComments = async (crms_ids) => {
    try {
      const data = await getCommentsCrm(crms_ids);
      setappComments(data.response);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  // useEffect para llamar a fetchComments cuando appCrms cambie
  useEffect(() => {
    if(!crm_list) return;
    const appCrms = crm_list?.filter((crm) => crm.action_type_id == 1);

    if (appCrms.length > 0) {
      const crms_ids = appCrms.map((crm) => crm.id); // Obtener los IDs de los crms
      fetchComments(crms_ids);
    }
  }, [crm_list]);
const isFuture = (alert)=>{
  const now = dayjs().format('YYYY-MM-DD')
  const alertDate = dayjs(alert.sent_at).format('YYYY-MM-DD')
  return dayjs(alertDate).isAfter(now)
}
  return (
    <>
      <CardRounded styles='py-6  mt-6 lg:mt-0'>
        <div className='flex flex-row items-center gap-7 px-6 '>
          <h2 className='text-2xl font-MessinaSansSemiBold text-white'>
            {t('crm.title', 'Seguimiento')}
          </h2>
          <Select
            ref={filterRef}
            showSearch
            placeholder={
              <div className='flex items-center gap-3'>
                <BsFilterLeft size={19} color='#FFF' />
                <span className='text-white font-MessinaSansSemiBold'>
                  {t('filter', 'Filtrar')}
                </span>
              </div>
            }
            allowClear
            mode='tags'
            optionFilterProp='children'
            onChange={onChangeFilter}
            value={filterSelected}
            bordered={false}
            rootClassName='text-white'
            className={`bg-primaryGrey text-white outline-none   rounded-md  font-DrukWideMedium py-1`}
            style={{
              width: 200,
              color: '#FFFFFF',
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={CRM_OPTIONS.map((option, i) => ({
              value: option.action_type_id,
              label: option.title,
            }))}
          />
          <div className='ml-auto'>
            <AddButton
              title={t('crm.add', 'Nuevo CRM')}
              greenColor
              onClick={() => handleOpenCRM(true)}
            />
          </div>
        </div>
        <div className='overflow-y-scroll max-h-[85vh] mt-6 px-6 overflow-x-hidden'>
          {isLoading ? (
            <Skeleton active paragraph={{ rows: 40 }} />
          ) : crm_list.length === 0 ? (
            <div className='flex flex-col jic py-8'>
              <Seguimiento />
              <h2 className='text-xl font-MessinaSansRegular text-[#888] text-center mt-8'>
                {t('crm.empty', 'Cargar seguimiento con el + para comenzar')}
              </h2>
            </div>
          ) : (
            Array.isArray(crm_list) &&
            Array.isArray(sales) &&
            Array.isArray(coachHistories)&&
            [ ...(crm_list ? crm_list : []), ...(sales ? sales : []) ,...(coachHistories ? coachHistories : [])]
            // .sort((a, b) => compareByCreatedAt(a, b))
              .sort((a, b) => compareByCreatedAtOrSentAt(a, b))
              .filter((action) =>
                filterSelected.length > 0
                  ? filterSelected.includes(action.action_type_id)
                  : true
              )
              .map((action, i) => (
              <CrmMessage
              action={action}
              i={i}
              key={i}
              confirm={confirm}
              handleOpenCRM={handleOpenCRM}
              handleOpenReturn={handleOpenReturn}
              appComments={appComments}
              cancel={cancel}
              confirmLoading={confirmLoading}
              open={open}
              data={data}
              user={user}
              state={state}
              setOpen={setOpen}
              isFuture={isFuture}
              CRM_OPTIONS={CRM_OPTIONS}
              />
              ))
          )}
        </div>
      </CardRounded>
      {messageContext}
    </>
  );
}
