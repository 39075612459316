import React, { useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Modal, ConfigProvider, DatePicker, Select, message } from 'antd';
import CardRounded from '../CardRounded';
import AddButton from '../helpers/AddButton';
import es_ES from 'antd/locale/es_ES';
const { confirm } = Modal;
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateDebit } from '../../api/debits';
import { formatError } from '../../services/utils/formatError';
import { canCreateDebit, isUserAdmin } from '../../services/can_user';
import { t } from 'i18next';
import ModalDebitCard from '../helpers/Modals/ModalDebitCard';
import LineDebitMember from '../helpers/LineDebitMember';
import { openLinkNotification } from '../../services/utils/openNotificationFields';
import { useAuth } from '../../context/AuthProvider';
import { pay_by_link_tokens } from '../../api/mp';
import {
  getCurrency,
  getCurrencyLocale,
  getLocationById,
  getLocationId,
} from '../../services/utils/getLocation';
import { closeMessageApi, loadingLink } from './MessageApi';
import UpdateDebitAttr from '../helpers/Modals/UpdateDebitAttr';
import { useGlobalContext } from '../../context/GlobalState';
import { BsChevronDown} from 'react-icons/bs';

// import AiOutlineDelete from 'react-icons/ai';
export default function DebitMember({
  debits,
  handleOpenDebit,
  isProspect,
  openNotification,
  customer_id,
  dischargeReasons,
  user_id,
  api,
  member_name,
  paymentPlatform,
}) {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const { state } = useGlobalContext();
  const dischargeReasonsFilter = dischargeReasons?.filter(
    (reason) => reason.discharge_reason_type_id == 6
  );
  const [openNotification1, setopenNotification1] = useState(false);
  const [openDebitUpdate, setopenDebitUpdate] = useState(false);
  const new_start_dateRef = useRef();
  const new_end_dateRef = useRef();
  const datepickerRef = React.useRef();
  const datepickerPauseRef = React.useRef();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const date_pause_until = React.useRef();
  const [datePauseUntil, setDatePauseUntil] = useState(null);
  const reasonSelectedRef = useRef();
  const [messageApi, contextHolderMessage] = message.useMessage();

  const handleCloseModal = () => {
    setopenNotification1(false);
  };
  const handleCloseDebit = () => {
    setopenDebitUpdate(false);
  };
  const handleOpenEditDebit = (debit, key) => {
    setopenDebitUpdate(debit);
  };
  const useUpdateDebit = useMutation({
    mutationFn: (debit) => updateDebit(debit.id, debit.data),
    onSuccess: (data) => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: ['profile', customer_id],
      });
    },
    onError: (error) => {
      formatError(error, openNotification);
    },
  });

  const updateDebitFn = async (debit, key, resolve, reject) => {
    let updateDebit = {
      id: debit.id,
      data: {},
    };
    if (key == 'add_pause' || key == 'modify_pause') {
      updateDebit.data.pause_until =
      key == 'modify_pause' ? false:
      dayjs.utc(debit.start_at).date() !== 1 ?
      dayjs(
        date_pause_until.current,
        'MMMM YYYY'
      )
        .set('date', dayjs(debit.start_at).date())
        .format('YYYY-MM-DD') :
      dayjs(
        date_pause_until.current,
        'MMMM YYYY'
      )
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
      setDatePauseUntil(null);
      // return
    } else if (key == 'edit_start') {
      updateDebit.data.start_at = new_start_dateRef.current;
    } else if (key == 'edit_end') {
      updateDebit.data.end_at = new_end_dateRef.current;
    } else {
      updateDebit.data.end_at = dayjs().format('YYYY-MM-DD');
      updateDebit.data.discharge_reason_id = reasonSelectedRef.current;
    }
    new_start_dateRef.current = null;
    new_end_dateRef.current = null;
    reasonSelectedRef.current = null;
    date_pause_until.current = null;
    try {
      await useUpdateDebit.mutateAsync(updateDebit);
      resolve();
    } catch (error) {
      reject(error);
    }
  };
  let debit_not_first = (debit) => {
    const debit_location =  getLocationById(state.locations, debit.location_id) 

    // if(isUserAdmin()){
    //   return false
    // }
    
    if(dayjs.utc(debit.start_at).date() !== 1){
      return false
    }
    return true
  }
  const editProduct = (debit) => {
  const debit_location =  getLocationById(state.locations, debit.location_id) 
    let options = [
      {
        label: <p>{t('modify_start', 'Modificar Inicio')}</p>,
        key: 'edit_start',
        disabled:
         ( debit.start_at == dayjs().format('YYYY-MM-DD') ||
          dayjs(debit.start_at).isBefore(dayjs(), 'day')
        ) && debit_not_first(debit) ,
      },
      {
        label: <p>{t('modify_end', 'Modificar Fin')}</p>,
        key: 'edit_end',
      },
      {
        label: <p>{t('modify_discount', 'Modificar Descuento')}</p>,
        key: 'edit_discount',
      },
      {
        label: <p>{t('modify_product', 'Modificar Producto')}</p>,
        key: 'edit_product',
      },
      {
        type: 'divider',
      },
      {
        label: <p>{debit.pause_until ? 'Modificar' : 'Agregar'} Pausa</p>,
        key: 'add_pause',
        // disabled: !state.user.roles.some((r)=>r.id == 7)
        disabled: !(debit_location?.country_id === 5 || debit_location?.country_id === 14 ||debit_location?.country_id === 13||debit_location?.id === 67|| state.user.roles.some((r)=>r.id == 7))      },
      {
        type: 'divider',
      },
      {
        label: (
          <p className=' text-primaryRed'>
            {t('disable_automatic_debit', 'Desactivar')}
          </p>
        ),
        key: 'delete',
        // disabled:true
      },
    ];
    return options;
  };
  const editSplit = (split) => {
    let options = [
      {
        label: (
          <p className=''>
            {t('modify_payment_method', 'Modificar metodo de pago')}
            {/* {t('disable_automatic_debit', 'Desactivar debito automatico')} */}
          </p>
        ),
        key: 'modify_payment_method',
        // disabled:true
      },
      {
        label: (
          <p className=''>
            {t('generate_load_link', 'Generar link de carga')}
            {/* {t('disable_automatic_debit', 'Desactivar debito automatico')} */}
          </p>
        ),
        key: 'generate_payment_link',
        disabled: !paymentPlatform
          .map((p) => p.payment_method_id)
          .includes(split.payment_method_id),
      },
      {
        label: (
          <p className=''>
            {t('modify_card_method', 'Modificar tarjeta')}
            {/* {t('disable_automatic_debit', 'Desactivar debito automatico')} */}
          </p>
        ),
        key: 'modify_card_method',
        disabled: !paymentPlatform
          .map((p) => p.payment_method_id)
          .includes(split.payment_method_id),
      },
    ];

    return options;
  };
  const showPromiseConfirm = (key, debit) => {
    // Función para manejar el cambio de fecha y actualizar el contenido del modal
    const handleDateChange = (date, dateString) => {
      // dayjs.utc(debit.start_at).date() !== 1
      
      setDatePauseUntil(dateString);
      date_pause_until.current = dateString;
      modal.update({
        content: getModalContent(key, debit, dateString),
      });
    };
    const handleRemoveDate = () => {
      setIsDatePickerOpen(false); // Cierra el DatePicker
      date_pause_until.current = null; // Elimina la fecha seleccionada
      modal.update({
        content: getModalContent(key, debit, ''),
      });
    };
    const handleAddReason = (value) => {
      reasonSelectedRef.current = value;
      modal.update({
        content: getModalContent(key, debit, ''),
      });
    };
    const getModalContent = (key, debit, datePauseUntil) => (
      <div>
        {key === 'edit_start' || key === 'edit_end' ? (
          <ConfigProvider locale={es_ES}>
            <DatePicker
              defaultValue={false}
              format={'DD/MM/YYYY'}
              defaultPickerValue={getLocationById(state.locations, debit.location_id)?.country_id === 5 && dayjs.utc(debit.start_at).date() !== 1 ?
              dayjs.utc(debit.start_at).date(1) : dayjs()}

              
              placeholder={`${t('new_date_start', 'Ingrese nueva fecha de')} ${
                key === 'edit_start' ? t('start', 'Inicio') : t('end', 'Fin')
              }`}
              ref={datepickerRef}
              allowClear={false}
              disabledDate={(current) => {
                if (key === 'edit_end') {
                  return (
                    (current && current.isBefore(dayjs(), 'day')) ||
                    dayjs(current).month() <= dayjs().month()
                  );
                }
                
                if (dayjs.utc(debit.start_at).date() !== 1) {
                  // Only allow dates within the month and year of the start date.
                  const startDate = dayjs(debit.start_at);
                  return (
                    current.year() !== startDate.year() ||
                    current.month() !== startDate.month()
                  );
                }
                if (getLocationById(state.locations, debit.location_id).debit_every_day) {
                  return current && current.isBefore(dayjs(), 'day');
                }
                return (
                  current &&
                  (current.date() !== 1 || current.isBefore(dayjs(), 'day'))
                );
              }}
              showNow={false}
              onChange={(date, dateString) => {
                datepickerRef.current.blur();
                if (key === 'edit_end') {
                  new_end_dateRef.current = dateString;
                } else {
                  new_start_dateRef.current = dateString;
                }
              }}
              className='shadow-none bg-primaryGrey outline-none border-none py-2 rounded-sm w-full my-2 hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey'
            />
          </ConfigProvider>
        ) : key === 'add_pause' ? (
          <div>
            <p className='text-primaryRed my-3'>
              {getLocationById(state.locations, debit.location_id).country_id == 5 ? t('add_pause_desc_es',`Esta acción detendrá el débito automático y le cargara el producto a valor 0 con un descuento especial`):t('add_pause_desc',`Esta acción detendrá el débito automático y no se cargará ningún
              producto hasta el mes seleccionado`)}
              .
            </p>
            {debit.pause_until && (
              <div className='flex flex-row items-center'>
                <p className='text-white'>
                  La fecha actual de reanudación es{' '}
                  {
                  dayjs.utc(debit.start_at).date() !== 1 ? dayjs
                    .utc(debit.pause_until)
                    .set('date', dayjs(debit.start_date).date()).format('DD/MM/YYYY'):
                  dayjs.utc(debit.pause_until).add(1,'day').format('DD/MM/YYYY')}{' '}
                  <button
                    onClick={async () => {
                      const r = new Promise((resolve, reject) => {
                        updateDebitFn(debit, key, resolve, reject);
                      });
                      r.then(() => {
                        modal.destroy();
                      });
                    }}
                    className='text-primaryRed hover:text-primaryRed underline'
                  >
                    Quitar fecha de pausa
                  </button>
                </p>
              </div>
            )}

            <ConfigProvider locale={es_ES}>
              <DatePicker
                // open={isDatePickerOpen}
                // onClick={(status) => setIsDatePickerOpen(status)}
                defaultValue={false}
                placeholder={`Ingrese mes de reanudación`}
                picker='month'
                ref={datepickerPauseRef}
                allowClear={false}
                disabledDate={(current) => {
                  return (
                    (current &&
                      dayjs(current).month() <= dayjs().month() + 1) ||
                    dayjs(current).month() > dayjs().month() + 3
                  );
                }}
                onChange={handleDateChange} // Actualiza dinámicamente el contenido
                format={'MMMM YYYY'}
                className='shadow-none bg-primaryGrey outline-none border-none py-2 rounded-sm w-full my-2 hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey'
              />
            </ConfigProvider>
            {datePauseUntil && (
              <p className='text-white'>
                A partir de {datePauseUntil} se reaunudará la carga de producto
              </p>
            )}
          </div>
        ) : (
          <div>
          <p className='text-primaryRed my-3'>
            {t(
              'disable_automatic_debit_description',
              '¿Estás seguro que quieres desactivar el débito automático?'
            )}
          </p>
          <p className='text-primaryRed my-3'></p>
              <Select
                value={reasonSelectedRef.current}
                onChange={(value) => {
                   handleAddReason(value);
                }}
                bordered={false}
                placeholder={t('select_reason', 'Seleccione un motivo')}
                suffixIcon={<BsChevronDown color='#ADFF19' />}
                className='bg-primaryGrey text-white outline-none border-none  rounded-md  font-DrukWideMedium py-1 my-4 '
                style={{ width: '100%' }}
                options={dischargeReasonsFilter?.map((reason) => ({
                  label:
                    reason.name +
                    (reason.description ? ' - ' + reason.description : ''),
                  value: reason.id,
                }))}
              />
          </div>
        )}
      </div>
    );
    const modal = Modal.confirm({
      forceRender: true,
      destroyOnClose: true,
      title:
        key === 'edit_start'
          ? t('modify_start', 'Modificar fecha inicio')
          : key === 'edit_end'
          ? t('modify_end', 'Modificar fecha fin')
          : key === 'add_pause'
          ? debit.pause_until
            ? t('modify_pause', 'Modificar pausa')
            : t('add_pause', 'Agregar pausa')
          : t('disable_automatic_debit', 'Desactivar débito automático'),
      okText: t('confirm', 'Confirmar'),
      cancelText: t('cancel', 'Cancelar'),
      cancelButtonProps: {
        className: 'text-white',
      },
      content: getModalContent(key, debit, ''), // Inicializa el contenido vacío
      onOk() {
        return new Promise((resolve, reject) => {
          updateDebitFn(debit, key, resolve, reject);
        });
      },
      onCancel() {
        new_start_dateRef.current = null;
        new_end_dateRef.current = null;
        reasonSelectedRef.current = null;
        date_pause_until.current = null;
      },
    });
    // Genera el contenido dinámico del modal
  };

  const handleLink = async (id, product_id, product_amount) => {
    loadingLink(messageApi);
    try {
      const createLink = await pay_by_link_tokens({
        location_id: getLocationId(),
        expiration_minutes: 0,
        token: user.single_access_token,
        user_id: user.user_id,
      });
      messageApi.destroy();
      // Aquí creas el link para compartir
      const linkToCopy = `https://gym.bigg.fit/mp?location_id=${getLocationId()}&owner_user_id=${user_id}&split_id=${id}&uuid=${
        createLink.uuid
      }&p_id=${product_id}&curr=${getCurrency()}&loc=${getCurrencyLocale()}&p_a=${product_amount}`;
      // Muestra la notificación de éxito si todo fue bien
      openLinkNotification(
        'success',
        `Compartile este link a ${member_name} para que pueda cargar su método de pago`,
        'Link generado con exito',
        linkToCopy,
        api,
        closeMessageApi,
        messageApi
      );
    } catch (error) {
      openLinkNotification(
        'error',
        'Hubo un problema al copiar el link al portapapeles. Por favor, intentalo nuevamente.',
        api,
        closeMessageApi
      );
    }
  };
  return (
    <div className='mb-10'>
      <div className='flex flex-row items-center justify-between mb-4 mr-1'>
        <h3 className='font-MessinaSansSemiBold text-xl '>
          {t('debit.title', 'Débito Automático')}
        </h3>
        {!isProspect && canCreateDebit() && (
          <AddButton
            title={t('add_debit', 'Cargar debito automatico')}
            greenColor
            onClick={handleOpenDebit}
          />
        )}
      </div>
      <CardRounded>
        <div className=' flex flex-row items-center  mt-4 gap-10 mx-5'>
          <span className=' text-primaryGrey w-[11%] uppercase'>
            {t('start', 'Inicio')}
          </span>
          <span className=' text-primaryGrey w-[11%] uppercase '>
            {t('end', 'Fin')}
          </span>
          <span className=' text-primaryGrey w-[30%] uppercase '>
            {t('product', 'producto')}
          </span>
          <span className=' text-primaryGrey w-[30%] uppercase '>
            {t('discount', 'Descuento')}
          </span>

          <span className=' text-primaryGrey w-[12%] uppercase'>
            {t('state_singular', 'Estado')}
          </span>

          <span className=' text-primaryGrey w-[6%] uppercase'>
            {/* {t('state_singular', 'Estado')} */}
          </span>

          <span className=' text-primaryGrey w-[4%] border-r-2'></span>
        </div>
        <LineDebitMember
          debits={debits}
          editProduct={editProduct}
          editSplit={editSplit}
          openNotification={openNotification}
          showPromiseConfirm={showPromiseConfirm}
          datePauseUntil={datePauseUntil}
          setopenNotification1={setopenNotification1}
          handleLink={handleLink}
          handleOpenDebit={handleOpenEditDebit}
        />
        <ModalDebitCard
          openNotification={openNotification}
          current_split={openNotification1}
          user_id={user_id}
          open={openNotification1}
          customer_id={customer_id}
          setClose={handleCloseModal}
        />
        <UpdateDebitAttr
          setClose={handleCloseDebit}
          data={openDebitUpdate}
          customer_id={customer_id}
          openNotification={openNotification}
          membership={openDebitUpdate?.edit == 'edit_product'}
        />
      </CardRounded>
      {contextHolderMessage}
    </div>
  );
}
