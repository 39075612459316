import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TableCustom from '../components/TableCustom';
import dayjs from 'dayjs';
import { USE_FORM_SALE } from '../services/Constants/FORMS';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification, ConfigProvider, DatePicker } from 'antd';
import { USESalesColumns } from '../services/Constants/AccountingColumns';
import { formatError } from '../services/utils/formatError';
import {
  createSale,
  deleteSale,
  getSale,
  updateSale,
  useGetSales,
} from '../api/sales';
import { getPointSales } from '../api/locations';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import CardRounded from '../components/CardRounded';
import es_ES from 'antd/locale/es_ES';
import { canUpdateSales, isUserAdmin } from '../services/can_user';
import { t } from 'i18next';
import { getDiscounts } from '../api/discounts';
import { useOutletContext } from 'react-router-dom';
import SpinIndicador from '../components/helpers/SpinIndicador';
import { formatPrice } from '../services/utils/formatPrice';
import { useGlobalContext } from '../context/GlobalState';

const start = dayjs().startOf('month');
const end = dayjs();
export default function Sales() {
  const SALES_COLUMNS = USESalesColumns();
  const [columns, setColumns] = useState(SALES_COLUMNS);
  const [activeFilter, setactiveFilter] = useState(false)
  const [
    modalMemberOpen,
    setModalMemberOpen,
    setmodalSale,
    setmodalTransaction,
    setTypeMemberCreate,
  ] = useOutletContext();
  const TITLE = t('sales.title', 'Ventas');
  const TITLE_SINGULAR = t('sales.title_singular', 'Venta');
  const FORM_SALE = USE_FORM_SALE();
  const [start_date, setstart_date] = useState(start);
  const [end_date, setend_date] = useState(end);
  const { data, isLoading } = useGetSales(
    start_date.format('YYYY-MM-DD'),
    end_date.format('YYYY-MM-DD')
  );
  const [filterTable, setfilterTable] = useState(isLoading ? [] : data);
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();

  const datepickerFrom = React.useRef();
  const datepickerAt = React.useRef();
  const { state } = useGlobalContext();

  useEffect(() => {
    setfilterTable(isLoading ? [] : data);
    const createFilters = (data, key, obj_key) =>
      [...new Set(data.map((d) => d[key]))].flat().reduce((acc, value) => {
        if (!acc.some((item) => item.text == value[obj_key])) {
          acc.push({ text: value[obj_key], value: value[obj_key] });
        }
        return acc;
      }, []);
    if (data) {
      let hasCardAttempts = data.some((item) => item.card_attempts.length > 0);
      if (hasCardAttempts) {
        setColumns(
          SALES_COLUMNS.map((item) => {
            if (item.dataIndex === 'card_attempts') {
              return {
                ...item,
                filters: createFilters(data, item.dataIndex, 'status'),
                onFilter: (value, record) => {
                  if (value === 'approved' || value === 'in_process') {
                    return record.card_attempts.some(
                      (item) => item.status === value
                    );
                  } else {
                    return (
                      record.card_attempts.every(
                        (item) => item.status !== 'approved'
                      ) &&
                      record.card_attempts.some((item) => item.status === value)
                    );
                  }
                },
                hidden: false,
              };
            }
            if (item.dataIndex === 'payments') {
              return {
                ...item,
                filters: createFilters(
                  data,
                  item.dataIndex,
                  'payment_method_name'
                ),
                onFilter: (value, record) =>
                  record.payments.some(
                    (item) => item.payment_method_name == value
                  ),
              };
            }
            return item;
          })
        );
      } else {
        setColumns(SALES_COLUMNS);
      }
    }
  }, [data]);

  const handleDelete = async (id) => {    
   
    await useDeleteSale.mutateAsync(id);
  };
  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ? t('sale_success', 'Venta actualizada correctamente')
          : t('sale_error', 'Ocurrió un error al actualizar la venta'),
      ...openNotificationFields(type, description),
    });
  };

  const [FORM, setFORM] = useState(FORM_SALE);
  const [searchInput, setsearchInput] = useState('');
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (!openDrawer.record) {
      let sale = {
        data: data,
      };
      await useCreateSale.mutateAsync(sale);
      return setopenDrawer({ visible: false, record: null });
    }
    let sale = {
      id: openDrawer.record.id,
      data: {
        id: openDrawer.record.id,
        ...data,
      },
    };
    await useUpdateSale.mutateAsync(sale);
    setopenDrawer({ visible: false, record: null });
  };
  const handleOpenDrawer = async (visible, record) => {
    reset();

    if (record) {
      setModalMemberOpen({
        id: record.customer_id,
        isProspect: record.customer_type == 'Prospect' ? true : false,
        customer_type: record.customer_type,
      });
    }
    return;
    if (visible) {
      try {
        let sale;
        if (record) {
          sale = await getSale(record.id);
          setValue('product_id', sale.product_id);
          setValue('name', sale.name);
          setValue('description', sale.description);
          setValue('point_of_sale_id', sale.point_of_sale_id);
          setValue('customer_id', sale.customer_id);
          setValue('description', sale.description);
          setValue('discount_id', sale.discount_id);
          setValue('amount', parseFloat(sale.amount));
          setValue('quantity', sale.quantity);
          setValue('tax_amount', sale.tax_amount);
          setValue('total', parseFloat(sale.total));
          setValue('product_name', sale.product.name);
          setValue('customer_name', sale.customer_name);
        }
        // const products = await getProducts()
        const point_of_sale_id = await getPointSales();
        // const members = await getViewMembers();
        const discounts = await getDiscounts();
        // console.log('products',products);

        // UPDATE DE FORM_PRODUCT WITH PRODUCT_TYPES
        setFORM(
          FORM.map((item) => {
            if (item.name === 'discount_id') {
              return {
                ...item,
                options: discounts.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            if (item.name === 'point_of_sale_id') {
              return {
                ...item,
                options: point_of_sale_id.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            // if (item.name === 'product_id') {
            //   return {
            //     ...item,
            //     options: products.map((item) => ({
            //       label: item.name,
            //       value: item.id,
            //       disabled:true
            //     })),
            //   };
            // }

            return item;
          })
        );

        setopenDrawer({ visible: visible, record: record });
      } catch (error) {
        console.error('Error opening drawer:', error);
      }
    } else {
      setopenDrawer({ visible: visible, record: record });
    }
  };
  const useUpdateSale = useMutation({
    mutationFn: (sale) => updateSale(sale.id, sale.data),
    onSuccess: () => {
      openNotification('success');

      queryClient.invalidateQueries({
        queryKey: ['sales'],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useCreateSale = useMutation({
    mutationFn: (sale) => createSale(sale.data),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: ['sales'],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useDeleteSale = useMutation({
    mutationFn: (id) => deleteSale(id),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: ['sales'],
      });
      setConfirmLoading(false);
    },
    onError: (error) => {
      formatError(error, openNotification);

      setConfirmLoading(false);
    },
  });
  const handleClickFilter = (name,filter)=>{
    if(activeFilter == name){
      setfilterTable(data)
      return setactiveFilter(false)
    }
    setfilterTable(data.filter(filter))
    setactiveFilter(name)
  }
  return (
    <>
      <div className='h-screen pl-[1rem] lg:pl-[3rem]   mt-8 gap-9  '>
        <div className='mb-10 flex-grow flex flex-col mt-2'>
          <h2 className='text-2xl font-MessinaSansSemiBold'>
            {t('sales.title', 'Ventas')}
          </h2>
          <div className={`flex flex-row justify-between mr-10 flex-wrap `}>
          <CardRounded styles={' mt-6 py-1 relative flex flex-row w-[25%]'}>
            {[t('fromLabel', 'Desde'), t('toLabel', 'Hasta')].map((item, i) => (
              <div
                key={i}
                className='relative   border-b-primaryGrey pt-7 pb-2 px-5 last:border-b-transparent flex flex-row items-center'
              >
                <span className=' opacity-40 absolute top-2 font-MessinaSansSemiBold'>
                  {item}
                </span>
                <ConfigProvider locale={es_ES}>
                  <DatePicker
                    allowClear={false}
                    ref={i == 0 ? datepickerFrom : datepickerAt}
                    onChange={(date, dateString) => {
                      if (i == 0) {
                        datepickerFrom.current.blur();
                        return setstart_date(date);
                      }
                      if (i == 1) {
                        datepickerAt.current.blur();
                        return setend_date(date);
                      }
                    }}
                    value={i == 0 ? start_date : end_date}
                    showToday
                    className=' bg-transparent outline-none border-none  h-12  w-full appearance-none border-transparent shadow-none hover:bg-primaryGrey focus:bg-primaryGrey focus-within:bg-primaryGrey'
                  />
                </ConfigProvider>
              </div>
            ))}
          </CardRounded>
          <CardRounded styles={`mt-6  py-3 relative px-6 flex cursor-pointer  items-center flex ${activeFilter == 'due_total' && '!bg-lightGreen !text-black'}`}>
            
            <div
            onClick={()=>{
              handleClickFilter('due_total', (p) => p.due_total > 0)
            }}
            className='flex flex-col items-center justify-center '>
              {isLoading ? (
                <SpinIndicador color={'fill-secondaryGrey'} />
              ) : (
                <h3 className={`text-center font-DrukWideMedium ${activeFilter == 'due_total' ? 'text-black' : 'text-white'} text-xl`}>
                  {
                   formatPrice( data.reduce((acc, item) => {
                    return acc + parseFloat(item.due_total);
                  } , 0))
                  }
                </h3>
              )}{' '}
              <h3 className=' text-center mt-2'>{t('cc_title','Cuenta corriente')}</h3>
            </div>
          </CardRounded>
          </div>
        </div>
        <div className='w-[100%] mt-[-40px]'>
          <TableCustom
            hideTitle
            filterTable={filterTable}
            data={data}
            canUpdate={canUpdateSales()}
            multipleTables
            openDrawer={openDrawer}
            setfilterTable={setfilterTable}
            handleOpenDrawer={handleOpenDrawer}
            title={TITLE}
            title_singular={TITLE_SINGULAR}
            searchInput={searchInput}
            onSubmit={onSubmit}
            control={control}
            FORM={FORM}
            handleDelete={ isUserAdmin() ? handleDelete : false}
            originalColumns={columns}
            register={register}
            handleSubmit={handleSubmit}
            setsearchInput={setsearchInput}
            isLoading={isLoading}
            mutateLoading={useUpdateSale.isLoading || useCreateSale.isLoading}
          />
        </div>
        {contextHolder}
      </div>
    </>
  );
}
