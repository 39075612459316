import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TableCustom from '../components/TableCustom';

import { USE_FORM_TRANSACTION } from '../services/Constants/FORMS';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { USETransactionsColumns } from '../services/Constants/AccountingColumns';
import { formatError } from '../services/utils/formatError';
import { useGetTransactions } from '../router/loaders/AccountingLoaders';
import { getPointSales } from '../api/locations';
import { createTransaction, deleteTransaction, getTransaction, getTransactionCategories, updateTransaction } from '../api/Transactions';
import { getPayment_methods } from '../api/payments';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import { t } from 'i18next';
import { isUserAdmin } from '../services/can_user';
const TITLE = t('transactions.title', 'Transacciones');
const TITLE_SINGULAR = t('transactions.title_singular', 'Transacción');
export default function Transactions() {
  const columns = USETransactionsColumns();
  const { data, isLoading } = useQuery(useGetTransactions());
  const FORM_TRANSACTION = USE_FORM_TRANSACTION();

  const [filterTable, setfilterTable] = useState(isLoading ? [] : data);
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  useEffect(() => {
    setfilterTable(isLoading ? [] : data);
  }, [data]);

  
  const handleDelete = async (id) => {
    await useDeleteSale.mutateAsync(id);
 
  };
  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ? t('transaction_success','Transacción actualizada correctamente')
          : t('transaction_error','Ocurrió un error al actualizar la transacción'),
...openNotificationFields(type,description)
    });
  };

  const [FORM, setFORM] = useState(FORM_TRANSACTION);
  const [searchInput, setsearchInput] = useState('');
  const [openDrawer, setopenDrawer] = useState({
    visible: false,
    record: null,
  });
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (!openDrawer.record) {
      let sale = {
        data: data,
      };
      await useCreateTransaction.mutateAsync(sale);
      return setopenDrawer({ visible: false, record: null });
    }
    let sale = {
      id: openDrawer.record.id,
      data: data,
    };
    await useUpdateTransaction.mutateAsync(sale);
    setopenDrawer({ visible: false, record: null });
  };
  const handleOpenDrawer = async (visible, record) => {
    reset();
    if (visible) {
      try {
        let transaction;
        if (record) {
          transaction = await getTransaction(record.id);
          setValue('point_of_sale_id', transaction.point_of_sale_id);
          setValue('payment_method_id', transaction.payment_method_id);
          setValue('transaction_type', transaction.transaction_type);
          setValue('transaction_category_id', transaction.transaction_category_id);
          setValue('amount', parseFloat(transaction.amount));
          setValue('description', transaction.description);
        }
        const point_of_sale_id = await getPointSales();
        const payment_methods = await getPayment_methods();
        const transaction_categories = await getTransactionCategories()

        // UPDATE DE FORM_PRODUCT WITH PRODUCT_TYPES
        setFORM(
          FORM.map((item) => {
            if(item.name === 'transaction_category_id'){
              return {
                ...item,
                options: transaction_categories.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            if (item.name === 'point_of_sale_id') {
              return {
                ...item,
                options: point_of_sale_id.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }

            if (item.name === 'payment_method_id') {
              return {
                ...item,
                options: payment_methods.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              };
            }
            return item;
          })
        );

        setopenDrawer({ visible: visible, record: record });
      } catch (error) {
        console.error('Error opening drawer:', error);
      }
    } else {
      setopenDrawer({ visible: visible, record: record });
    }
  };
  const useUpdateTransaction = useMutation({
    mutationFn: (sale) => updateTransaction(sale.id, sale.data),
    onSuccess: () => {
      openNotification('success');

      queryClient.invalidateQueries({
        queryKey: ['transactions'],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useCreateTransaction = useMutation({
    mutationFn: (sale) => createTransaction(sale.data),
    onSuccess: () => {
      openNotification('success');

      queryClient.invalidateQueries({
        queryKey: ['transactions'],
      });
    },
    onError: (error) => formatError(error, openNotification),
  });
  const useDeleteSale = useMutation({
    mutationFn: (id) => deleteTransaction(id),
    onSuccess: () => {
      openNotification('success');

      queryClient.invalidateQueries({
        queryKey: ['transactions'],
      });
      setConfirmLoading(false);
    },
    onError: (error) => {
      formatError(error, openNotification);

      setConfirmLoading(false);
    },
  });
  return (
    <>
      <TableCustom
        filterTable={filterTable}
        data={data}
        
        openDrawer={openDrawer}
        setfilterTable={setfilterTable}
        handleOpenDrawer={handleOpenDrawer}
        title={TITLE}
        title_singular={TITLE_SINGULAR}
        searchInput={searchInput}
        onSubmit={onSubmit}
        control={control}
        FORM={FORM}
        originalColumns={columns}
        register={register}
        handleSubmit={handleSubmit}
        setsearchInput={setsearchInput}
        isLoading={isLoading}
        handleDelete={ isUserAdmin() ? handleDelete : false}
        mutateLoading={useUpdateTransaction.isLoading || useCreateTransaction.isLoading}
      />
      {contextHolder}
    </>
  );
}
