import React, { useState, useEffect,useMemo } from 'react';
import { notification, ConfigProvider, DatePicker, Popover, Tooltip } from 'antd';
import { useOutletContext } from 'react-router-dom';

import TableCustom from '../components/TableCustom';
import { UseLeadsColumns } from '../services/Constants/UsersColumns';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { formatError } from '../services/utils/formatError';
import { deleteProspect } from '../api/prospects';
import dayjs from 'dayjs';
import { openNotificationFields } from '../services/utils/openNotificationFields';
import { t } from 'i18next';
import { useGlobalContext } from '../context/GlobalState';
import { useAuth } from '../context/AuthProvider';
import { DateRangePicker } from '../components/prospects/HeaderCharts';
import { deleteLead, useGetLeads } from '../api/leads';
const start = dayjs().startOf('month');
const end = dayjs().add(1, 'day');
export default function Leads() {
  const PROSPECTColumns = UseLeadsColumns()
  const {state}  = useGlobalContext()
  const {user} = useAuth()
  const TITLE = t('leads','Leads');
const TITLE_SINGULAR = t('lead','lead');
  const [
    modalMemberOpen,
    setModalMemberOpen,
    setmodalSale,
    setmodalTransaction,
    setTypeMemberCreate,
  ] = useOutletContext();
  const [start_date, setstart_date] = useState(start);
  const [end_date, setend_date] = useState(end);
  const { isLoading, isError, data, error } = useGetLeads(
    start_date.format('YYYY-MM-DD'),
    end_date.format('YYYY-MM-DD')
  );
  const datepickerFrom = React.useRef();
  const datepickerAt = React.useRef();
  const [filterTable, setfilterTable] = useState([]);
  const [columns,setColumns] = useState(PROSPECTColumns);
  const [api, contextHolder] = notification.useNotification();
  const [searchInput, setsearchInput] = useState('');
  // const [activeFilter, setActiveFilter] = useState('');
  const queryClient = useQueryClient();
  const handleDelete = async (id) => {
    await useDeleteProspect.mutateAsync(id);
  };
  useEffect(() => {
    setfilterTable(isLoading ? [] : data);
    if (data) {
      setColumns(
        PROSPECTColumns)
      }
  }, [data,isLoading]);
  const useDeleteProspect = useMutation({
    mutationFn: (id) => deleteLead(id),
    onSuccess: () => {
      openNotification('success');
      queryClient.invalidateQueries({
        queryKey: [
          'chatbot_leads',
          start_date.format('YYYY-MM-DD'),
          end_date.format('YYYY-MM-DD'),
        ],
      });
      // setConfirmLoading(false);
    },
    onError: (error) => {
      formatError(error, openNotification);
      // setConfirmLoading(false);
    },
  });
  const openNotification = (type, description) => {
    api[type]({
      message:
        type == 'success'
          ?  t('lead.updated','Lead actualizado correctamente')
          : t('lead.error','Ocurrió un error al actualizar el lead'),
      ...openNotificationFields(type, description),
    });
  };
  return (
    <div className='h-screen pl-[1rem] lg:pl-[3rem]    mt-8 gap-9  '>
      {contextHolder}
      <div className='mb-10 flex-grow flex flex-col mt-2 '>
        <h2 className='text-2xl font-MessinaSansSemiBold'>
          {TITLE} {isLoading ? '' : data.length}
        </h2>
        <div className={`flex flex-row justify-between mr-10 flex-wrap `}>
        <DateRangePicker
        start_date={start_date}
        end_date={end_date}
        setStartDate={setstart_date}
        setEndDate={setend_date}
        datepickerFrom={datepickerFrom}
        datepickerAt={datepickerAt}
      />
   
        </div>
      </div>
      <div className='w-[100%] mt-[-40px] '>
        <TableCustom
          hideTitle
          handleDelete={handleDelete}
          multipleTables
          filterTable={filterTable}
          data={data}
          openDrawer={() => {}}
          setfilterTable={setfilterTable}
          handleOpenDrawer={(visible, record) => {
            if (record) {
              setModalMemberOpen({
                id: record.member_id ? record.member_id : record.id,
                isProspect: record.member_id ? false : true,
                customer_type:'Lead',
                start_date: start_date.format('YYYY-MM-DD'),
                end_date: end_date.format('YYYY-MM-DD'),
              });
            } else {
              setTypeMemberCreate(true);
            }
          }}
          title={TITLE}
          title_singular={TITLE_SINGULAR}
          searchInput={searchInput}
          onSubmit={() => {}}
          originalColumns={columns}
          handleSubmit={() => {}}
          setsearchInput={setsearchInput}
          isLoading={isLoading}
          setColumns={setColumns}

        />
      </div>
    </div>
  );
}
