import React, { useState, useEffect ,useMemo,useCallback} from 'react';
import {
  notification,
Tooltip,
} from 'antd';
import {  useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import CardRounded from '../components/CardRounded';
import { BiCheckboxChecked, BiErrorAlt, BiXCircle } from 'react-icons/bi';
import TableCustom from '../components/TableCustom';
import dayjs from 'dayjs';
import { useGetReports } from '../api/reports';
import { use_REPORT_LIST_OPTIONS } from '../services/Constants/Reports';
import HelpQuestion from '../components/helpers/popovers/HelpQuestion';
import { getClasses } from '../api/gym_classes';
import { t } from 'i18next';
import { useGlobalContext } from '../context/GlobalState';
import { getLocationId } from '../services/utils/getLocation';
import { isUserManager} from '../services/can_user';
import ReportsHeader from '../components/helpers/ReportsHeader';
const startOfMonth = dayjs().startOf('month');
const endOfMonth = dayjs().add(1, 'month').startOf('month');
export default function Reports() { 
  const [
    modalMemberOpen,
    setModalMemberOpen,
    setmodalSale,
    setmodalTransaction,
    setTypeMemberCreate,
  ] = useOutletContext();
  const [searchParams] = useSearchParams(); // Obtener los parámetros de búsqueda (query params)
  const state = useGlobalContext();

  // Extraer las fechas desde los parámetros de la URL o usar valores por defecto
  const urlStartDate = searchParams.get('start');
  const urlEndDate = searchParams.get('end');
  const reportId = searchParams.get('r_id');
  const initialStartDate = urlStartDate ? dayjs(urlStartDate) : startOfMonth;
  const initialEndDate = urlEndDate ? dayjs(urlEndDate) : endOfMonth;
  const [start_date, setstart_date] = useState(initialStartDate);
  const [end_date, setend_date] = useState(initialEndDate);
  const REPORT_LIST_OPTIONS = use_REPORT_LIST_OPTIONS(start_date, end_date);
  const REPORT_LIST = REPORT_LIST_OPTIONS.reduce((acc, curr) => {
    return acc.concat(curr.options);
  }, []);
const [location_selected, setlocation_selected] = useState([getLocationId()])
  const [month_selected, setMonthSelected] = useState(start_date);
  const [active_checkbox, setactive_checkbox] = useState(false);
  const [isSelectAttendance, setisAttendance] = useState(null);
  const [gym_classes, setGymclasses] = useState([]);
  const [selectedClass, setselectedClass] = useState(null);
  const [selectSameMoment, setSelectSameMoment] = useState(null);
  const [openLocations, setopenLocations] = useState(false)
  const [reportSelected, setreportSelected] = useState(Number(reportId) || null); // Selecciona el reporte basado en el ID de la URL
  
  const { isLoading, isError, data, error } = useGetReports(
    location_selected,
    reportSelected,
    start_date.format('YYYY-MM-DD'),
    end_date.format('YYYY-MM-DD'),
    REPORT_LIST.find((r) => r.value == reportSelected)?.pickerMonth
      ? month_selected.format('YYYY-MM-DD')
      : null,
    active_checkbox,
    isSelectAttendance,
    selectedClass,
    selectSameMoment
  );
  const [filterTable, setfilterTable] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  const datepickerFrom = React.useRef();
  const datepickerMonth = React.useRef();

  const datepickerAt = React.useRef();
  const selectRef = React.useRef();

  const [columns, setColumns] = useState([]);
  const [searchInput, setsearchInput] = useState('');

  const reduceDebits = (data) => {
    if (!data) return [];
   return  data.reduce((acc, item) => {
      const index = acc.findIndex((el) => el.debit_id === item.debit_id);
      
      if (index === -1) {
        acc.push({
          debit_id: item.debit_id,
          ...item,
          amount : Number(item.price_debit_payment) || 0, // Asegurarse de que sea un número
          price_debit_payment: Number(item.price_debit_payment) || 0, // Asegurarse de que sea un número
        });
      } else {
        acc[index].price_debit_payment += Number(item.price_debit_payment) || 0; // Sumar como número
      }
      
      return acc;
    }, []);
  }
  useEffect(() => {
    if (isError) {
      api.error({
        message: t('errorLoadingData', 'Error al cargar los datos'),
        description: error.message,
        placement: 'bottomRight',
      });
    }
    if (!isLoading && data) {
      if(reportSelected== 14){
        let dataFiltered =reduceDebits(data)
    return setfilterTable(dataFiltered);
      }
      setfilterTable(data);
    }
  }, [data, isLoading, reportSelected]);


  const items = [
    ...[
      t('reports', 'Reportes'),
      t('fromLabel', 'Desde'),
      t('toLabel', 'Hasta'),
    ].filter((c) =>
      reportSelected &&
      REPORT_LIST.find((r) => r.value === reportSelected).pickerMonth
        ? c === 'Reportes'
        : c
    ),
    ...(REPORT_LIST.find((r) => r.value === reportSelected)?.pickerMonth
      ? ['Mes']
      : []),
    ...(REPORT_LIST.find((r) => r.value === reportSelected)?.activeFilter
      ? ['Activos']
      : []),
    ...(REPORT_LIST.find((r) => r.value === reportSelected)?.hasAttendance
      ? ['Asistencia']
      : []),
    ...(REPORT_LIST.find((r) => r.value === reportSelected)?.selectClass
      ? ['Clase']
      : []),
    ...(REPORT_LIST.find((r) => r.value === reportSelected)?.sameMoment
      ? [`Mismo  momento`]
      : []),
     ...isUserManager() ?  [`Sede`] :[]
  ];


  useEffect(() => {
    if (reportSelected && !isLoading && data && data.length > 0) {
      const createFilters = (data, key) =>
        data
          .filter((d) => d[key] &&  (typeof d[key] === 'string' && d[key]?.trim()?.length > 0 || typeof d[key] === 'number'))
          .map((d) => ({ text: d[key], value: d[key] }))
          .sort((a, b) => a.text > b.text)
          .reduce((acc, current) => {
            if (!acc.find((item) => item.value === current.value)) {
              acc.push(current);
            }
            return acc;
          }, []).concat(key == 'discharge_reason' ? [{ text: t('noDischargeReason', 'Sin RNC'), value: null }] : []);
  
      let columns = REPORT_LIST.find((r) => r.value == reportSelected).columns.map((column) => {
        const { dataIndex } = column;
        if (
          dataIndex === 'product_name' ||
          dataIndex === 'coach_assigned' ||
          dataIndex === 'discharge_reason' ||
          dataIndex === 'seller' ||
          dataIndex === 'channel' ||
          dataIndex === 'payment_methods_name' ||
          dataIndex === 'discount_name' ||
          dataIndex === 'username' ||
          dataIndex === 'coach_name' ||
          dataIndex === 'member_type' ||
          dataIndex === 'member_state' ||
          dataIndex === 'crm_type' ||
          dataIndex === 'product_type' ||
          dataIndex === 'member_period' ||
          dataIndex === 'total_uses'  ||
          dataIndex === 'details' ||
          dataIndex === 'antiquity'  || 
          dataIndex === 'type' ||
          dataIndex === 'operation_status'
        ) {
        
          return {
            ...column,
            filters: createFilters(data, dataIndex),
            onFilter: (value, record) => record[dataIndex] == value,
          };
        }
        return column;
      });
      if (reportSelected == 21) {
        const weekColumns = [];
        const weekAttributes = ['assigned_state'];
        data.forEach((d) => {
          if (Array.isArray(d.week_number)) {
            const sortedWeeks =  d.week_number.sort((a,b)=>a.week > b.week ? 1 : -1)
            sortedWeeks.forEach((week, i) => {
              weekAttributes.forEach((attr) => {
                const columnKey = `week_${week.week}_${attr}`;
                if (!weekColumns.find(col => col.key === columnKey) && attr !== 'weekly_uses') {
                  weekColumns.push({
                    title: `Semana ${week.week_range}`,
                    dataIndex: `week_number.${week.week}.${attr}`,
                    key: columnKey,
                    width: '6%',
                    render: (text, record) => {
              const weekData = record.week_number.find((w) => w.week == week.week);
                      if(!weekData) return null;
                      if(attr == 'assigned_state' ){
                        return (<Tooltip
                         title={weekData[attr] == 'OK' ? 'Debió ser contactado, se contacto' : weekData[attr] == 'NO' ? 'Se debió haber contactado, no se contacto' : weekData[attr] == 'REGULAR' ? 'No debía ser contactado, se contacto' : ''}
                        >
                          {weekData[attr] == 'OK' ?  
                          <BiCheckboxChecked size={29} color='#1EA05A' />
                        : weekData[attr] =='NO'? <BiXCircle size={29} color={'#ED4C5C'} /> : weekData[attr] == 'REGULAR'? <BiErrorAlt size={29} color='#FFD500' /> :<></>}
                        </Tooltip>)
                        
                      }
                      return weekData ? weekData[attr] : null;
                    },
                  });
                }
              });
            });
          }
        });
        columns = columns.concat(weekColumns);
      }
      if (location_selected.length > 1 && ![12, 16].includes(reportSelected)) {
        columns = columns.concat({
          title: t('location_singular', 'Sede'),
          dataIndex: 'location_name',
          key: 'location_name',
          width: '6%',
          render: (text, record) =>
            state.state.availableLocations.find((l) => l.id == record.location_id)?.name,
          filters: state.state.availableLocations
            .sort((a, b) => a.name > b.name)
            .map((l) => ({
              text: l.name,
              value: l.id,
            }))
            .filter((l) => location_selected.includes(l.value)),
          onFilter: (value, record) => record.location_id == value,
        });
      }
      if(reportSelected == 14){
        //filter column credit_card_id  if any register has credit_card_id
        const hasCreditCard = data.some((d) => d.credit_card_id);
        if(!hasCreditCard){
          columns = columns.filter((c) => c.dataIndex !== 'credit_card_id');
        }
      }
      setColumns(columns);
    }
  }, [reportSelected, isLoading, data]);
  
  useEffect(() => {
    if (reportSelected == 5) {
      fetchGymClasses();
    }
  }, [reportSelected]);

  const fetchGymClasses = async () => {
    const result = await getClasses();
    setGymclasses(
      result
        .filter((c) => c.parent_id)
        .map((c) => ({ label: c.name, value: c.name }))
    );
  };
  return (
    <div 
    className='h-screen pl-[1rem] lg:pl-[3rem]   mt-8 gap-9  '>
      {contextHolder}
      <div className='mb-10 flex-grow flex flex-col mt-2'>
        <h2 className='text-2xl font-MessinaSansSemiBold flex items-center gap-3'>
          {t('reports', 'Reportes')}
          <HelpQuestion
            description={`Ir al centro de ayuda`}
            link={`https://biggeye.kb.help/reportes`}
          />
        </h2>
        <CardRounded
          styles={' mt-6 py-1 relative flex flex-row mr-6   lg:mr-0   gap-0 lg:gap-10  w-fit  flex-wrap'}
        >
          {items.map((item, index) => (
            <ReportsHeader
              key={index}
              item={item}
              gym_classes={gym_classes}
              index={index}
              reportSelected={reportSelected}
              setreportSelected={setreportSelected}
              location_selected={location_selected}
              setlocation_selected={setlocation_selected}
              setfilterTable={setfilterTable}
              active_checkbox={active_checkbox}
              setactive_checkbox={setactive_checkbox}
              start_date={start_date}
              setstart_date={setstart_date}
              end_date={end_date}
              setend_date={setend_date}
              month_selected={month_selected}
              setMonthSelected={setMonthSelected}
              isSelectAttendance={isSelectAttendance}
              setisAttendance={setisAttendance}
              selectedClass={selectedClass}
              setselectedClass={setselectedClass}
              selectSameMoment={selectSameMoment}
              setSelectSameMoment={setSelectSameMoment}
              openLocations={openLocations}
              setopenLocations={setopenLocations}
              state={state}
              selectRef={selectRef}
              datepickerFrom={datepickerFrom}
              datepickerAt={datepickerAt}
              datepickerMonth={datepickerMonth}

            />
          ))}
        </CardRounded>
      </div>

      <div
      className='w-[100%] mt-[-40px]'>
        {reportSelected && (
          <TableCustom
            hideTitle
            multipleTables
            filterTable={filterTable}
            dataOriginal={data}
            data={
reportSelected == 14 ? reduceDebits(data) : data
            }
            openDrawer={() => {}}
            setfilterTable={setfilterTable}
            handleOpenDrawer={(visible, record) => {
              if (record) {
                if (reportSelected == 20) {
                  record.customer_type = 'Member';
                }
                setModalMemberOpen({
                  id: record.member_id
                    ? record.member_id
                    : record.prospect_id
                    ? record.prospect_id
                    : record.customer_id
                    ? record.customer_id
                    : record.id,
                  isProspect:
                    record.member_id || record.customer_type == 'Member'
                      ? false
                      : true,
                      customer_type:
                      record.customer_type ? record.customer_type : record.member_id ? 'Member' : 'Prospect'
                });
              } else {
                setTypeMemberCreate(true);
              }
            }}
            title={REPORT_LIST.find((r) => r.value == reportSelected)?.label}
            title_singular={''}
            showFooterTotal={REPORT_LIST.find((r) => r.value == reportSelected)?.showFooterTotal}
            handleDelete={false}
            searchInput={searchInput}
            onSubmit={() => {}}
            originalColumns={columns}
            handleSubmit={() => {}}
            setsearchInput={setsearchInput}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
}
