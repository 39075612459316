import React, { useState } from 'react';
import dayjs from 'dayjs';
import { t } from 'i18next';
import WhatsAppSvg from '../../assets/icons/WhatsAppSvg';
import CalendarSvg from '../../assets/icons/CalendarSvg';
import { getTimeZone } from '../../services/utils/getLocation';
import utc from 'dayjs/plugin/utc';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { BiTrash } from 'react-icons/bi';
import { isUserManager } from '../../services/can_user';
import { Popconfirm } from 'antd';

dayjs.extend(utc);
dayjs.extend(LocalizedFormat);
dayjs.locale('es');

const CrmWhatsApp = ({
  action,
  user,
  data,
  confirmLoading,
  open,
  setOpen,
  cancel,
  confirm
}) => {
  const [showFormatted, setShowFormatted] = useState(false); // New state for toggle view

  return (
    <div className="border-b-[1px] border-b-primaryGrey last:border-b-transparent">
      <div className="flex flex-row items-center gap-7">
        <WhatsAppSvg />
       
        <span>
          {action.user_first_name} {action.user_last_name} {t('crm.crm_source', 'se contactó por')} WhatsApp
          {action.sent_at && (
            <p className="opacity-75 font-MessinaSansSemiBold">
              {t('crm.date_conversation', 'Fecha Conversación')}{' '}
              {dayjs(action.sent_at_original).utc().tz(user.country.time_zone).format('YYYY-MM-DD HH:mm A')}
            </p>
          )}
        </span>
        {/* <button 
          onClick={() => setShowFormatted(!showFormatted)} 
          className="ml-auto px-3 py-1 bg-secondaryGrey rounded-md text-white" 
          type="button"
        >
          {showFormatted ? "Ver como era" : "Ver todo formateado"}
        </button> */}
        <span className="ml-auto text-primaryGrey flex gap-3">
          <CalendarSvg />
          {dayjs(action.created_at).tz(user.country.time_zone).format('LLL A')}
        </span>
      </div>
      <div className="pl-16 mt-2 flex flex-col gap-2">
        {showFormatted ? (
          // Formatted view with grouped lines
          (() => {
            const lines = action.message.split('\n');
            const groupedLines = lines.reduce((acc, curr) => {
              if (/^\[\d{1,2}:\d{2}/.test(curr)) {
                acc.push(curr);
              } else {
                if (acc.length) {
                  acc[acc.length - 1] = acc[acc.length - 1] + ' ' + curr;
                } else {
                  acc.push(curr);
                }
              }
              return acc;
            }, []);
            return groupedLines.map((item, i) => {
              const timeMatch = item.match(/^\[(\d{1,2}:\d{2}),\s*[\d/]+\]/);
              const cleanedItem = item
                .replace(/^\[[^\]]+\]\s+\+\d+[\d-\s]*/, '')
                .replace(/^:\s*/, '');
              const phonePattern = /^\[[^\]]+\]\s+\+\d+[\d-\s]*/;
              const isMine = cleanedItem.includes('BIGG') && !phonePattern.test(item);
              const finalMessage = isMine
                ? cleanedItem
                    .replace(/BIGG\s+TORTUGUITAS:\s*/i, '')
                    .replace(/\[[^\]]+\]\s*/g, '')
                    .trim()
                : cleanedItem.replace(/\[[^\]]+\]\s*/g, '').trim();
              const formattedTime = timeMatch
                ? dayjs(timeMatch[1], 'H:mm').format('HH:mm A')
                : (action.sent_at
                    ? dayjs(action.sent_at_original)
                        .utc()
                        .tz(user.country.time_zone)
                        .format('h:mm A')
                    : '');
              return (
                <div 
                  key={i} 
                  className={`max-w-[50%] px-5 py-2 rounded-2xl ${isMine ? 'self-end text-right bg-lightGreen' : 'self-start text-left bg-primaryGrey'}`}
                >
                  <p className={isMine ? 'text-primaryDark' : 'text-primaryWhite'}>
                    {finalMessage}
                  </p>
                  {formattedTime && (
                    <span className={isMine ? 'text-primaryDark' : 'text-primaryWhite'}>
                      {formattedTime}
                    </span>
                  )}
                </div>
              );
            });
          })()
        ) : (
          // Default view: render each line as is (simple newline split)
          
      <div className={` mt-2 flex justify-between `}>
      <div className={`w-[100%]`}>
        {action.message.split('\n').map((item, i) => (
          <p key={i}>{item}</p>
        ))}
        
      </div>
      {
          ((isUserManager() &&
            data.available_locations
              .map((d) => d.id)
              .includes(action.location_id)) ||
            action.creator_id == user.user_id) &&
            action.action_type_id !== 1 &&
            (
            <button className='cursor-pointer'>
              <Popconfirm
                title={t(
                  'crm.confirm_delete',
                  'Estás seguro que deseas eliminar esta acción?'
                )}
                // description='Se agregará una sesión al socio.'
                onConfirm={() => confirm(action.id)}
                onCancel={cancel}
                okText={t('accept', 'Aceptar')}
                placement='left'
                open={open == action.id}
                cancelText={t('cancel', 'Cancelar')}
                okButtonProps={{
                  loading: confirmLoading,
                  className:
                    'bg-primaryRed text-white rounded-md px-3 py-1 hover:bg-primaryRed ',
                }}
              >
                <BiTrash
                  size={25}
                  color='#ED4C5C'
                  onClick={() => setOpen(action.id)}
                />
              </Popconfirm>
            </button>
          )}
    </div>
        )}
      </div>
    </div>
  );
};

export default CrmWhatsApp;
