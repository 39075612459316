

import React from 'react'

export default function AppNps({large,bg}) {
    let width = 35
  let height = 35
  if( large ){
    width = 55
    height = 55
  }
  return (
<svg width="35" height="35" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.5 38.5C9.00659 38.5 0.5 29.9934 0.5 19.5C0.5 9.00659 9.00659 0.5 19.5 0.5C29.9934 0.5 38.5 9.00659 38.5 19.5C38.5 29.9934 29.9934 38.5 19.5 38.5Z" fill="#A8A8A8" stroke="#A8A8A8"/>
<path d="M24 10V12H27.007C27.555 12 28 12.445 28 12.993V29.007C27.9997 29.2703 27.895 29.5227 27.7089 29.7089C27.5227 29.895 27.2703 29.9997 27.007 30H10.993C10.7296 30 10.4771 29.8954 10.2908 29.7092C10.1046 29.5229 10 29.2704 10 29.007V12.993C10 12.445 10.445 12 10.993 12H14V10H24ZM14 14H12V28H26V14H24V16H14V14ZM16 24V26H14V24H16ZM16 21V23H14V21H16ZM16 18V20H14V18H16ZM22 12H16V14H22V12Z" fill="black"/>
</svg>

  )
}
