
import React from 'react'

export default function PushApp() {
  return (
    <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 1H7.4C5.16 1 4.04 1 3.184 1.436C2.43139 1.81949 1.81949 2.43139 1.436 3.184C1 4.04 1 5.16 1 7.4V12.6C1 14.84 1 15.96 1.436 16.816C1.81949 17.5686 2.43139 18.1805 3.184 18.564C4.04 19 5.16 19 7.4 19H12.6C14.84 19 15.96 19 16.816 18.564C17.5686 18.1805 18.1805 17.5686 18.564 16.816C19 15.96 19 14.84 19 12.6V10.5M19 3.75C19 4.47935 18.7103 5.17882 18.1945 5.69454C17.6788 6.21027 16.9793 6.5 16.25 6.5C15.5207 6.5 14.8212 6.21027 14.3055 5.69454C13.7897 5.17882 13.5 4.47935 13.5 3.75C13.5 3.02065 13.7897 2.32118 14.3055 1.80546C14.8212 1.28973 15.5207 1 16.25 1C16.9793 1 17.6788 1.28973 18.1945 1.80546C18.7103 2.32118 19 3.02065 19 3.75Z" stroke="#ADFF19" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  )
}
