import React from 'react'
import { formatSuscriptionDaysLeft } from '../../services/membersUtils';
import { Dropdown, Modal, Tooltip } from 'antd';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useGlobalContext } from '../../context/GlobalState';

export default function SuscriptionsList({suscription,editProduct,showPromiseConfirm,index,customer_id,expired,customer_type,openNotification}) {
  const startInFuture = dayjs(suscription.started_at,'YYYY-MM-DD').isAfter(dayjs().format('YYYY-MM-DD'));
    const { state } = useGlobalContext();
  
  return (
    <div
    key={suscription.id.toString()}
    className=' border-b-[1px] border-b-primaryGrey last:border-b-transparent w-full py-5 px-5'
  >
     {startInFuture && (
      <p className='text-xs font-MessinaSansSemiBold text-secondaryGrey mb-2 mt-[-5px]'>{t('starts_in','Inicia en')} {
        dayjs(suscription.started_at,'YYYY-MM-DD').diff(dayjs().format('YYYY-MM-DD'), 'days') > 1
          ? dayjs(suscription.started_at,'YYYY-MM-DD').diff(dayjs().format('YYYY-MM-DD'), 'days') + ' días'
          : dayjs(suscription.started_at,'YYYY-MM-DD').diff(dayjs().format('YYYY-MM-DD'), 'days') + ' día'
        }</p>
     )}
    <h4 className={`font-MessinaSansSemiBold text-lg ${ expired ? 'text-primaryRed': startInFuture ?'text-primaryYellow':'text-lightGreen'}`}>
      {suscription.membership.product.name}
    </h4>
    <div className='flex flex-row items-center gap-5'>
      <Tooltip title={`Inició el ${dayjs(suscription.started_at,'YYYY-MM-DD').format('YYYY-MM-DD')}`}>
      <span>{formatSuscriptionDaysLeft(suscription.started_at,suscription.days_left,customer_id) + ' '} 
        { formatSuscriptionDaysLeft(suscription.started_at,suscription.days_left,customer_id) > 1
          ?  t('days','días')
          : t('day_singular','día')} </span>
      </Tooltip>
      {suscription.membership.product.parent_product_id !== 32 && (
      <span>{suscription.sessions_left} {t('sessions', 'Sesiones')}</span>

      )}

    {(index == 0 && expired && customer_type == suscription.owner_type  || !expired) &&  <Tooltip title={t('edit_product','Editar producto')}>
        <Dropdown
          menu={{
            items: editProduct.filter((item) => item.visible),
            onClick: async ({ key }) => {
              console.log('s',state.user.locations,suscription.location_id);
              
              if(!state.user.locations
                .map((l) => l.id)
                .includes(suscription.location_id)){
 return  openNotification('error',t('location_error','No puedes editar productos de otra sede'))
                }
              if (
                key == 'edit_days' ||
                key == 'edit_start' ||
                key == 'edit_sessions'
              ) {
                showPromiseConfirm(key, suscription,customer_id);
              }
            },
          }}
          trigger={['click']}
        >
          <div className=' ml-auto rounded-full  border-primaryGray border-[1px] p-2 cursor-pointer hover:opacity-60 ease-in-out duration-200'>
            <MdOutlineModeEditOutline size={15} color='#ccc' />
          </div>
        </Dropdown>
      </Tooltip>}
    </div>
  </div>
  )
}
